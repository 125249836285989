import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarKeys } from 'src/modules/calendar/hooks/calendarKeys';
import { AbsenceAPI } from 'src/services/api/absenceApi';
import { AbsenceManagerCommentRequest, AbsenceRequest } from 'src/services/api/request/absenceRequest';
import { AbsenceResponse } from 'src/services/api/response/absenceResponse';

// type UpdateDataProps = {
//   ID: number;
//   From: string | Date;
//   To: string | Date;
//   Type: string | number;
//   Reason: string | number;
//   UserComment?: string | undefined;
//   UserID: string | undefined;
// };

const absenceApi = new AbsenceAPI();

const createAbsence = async (data: AbsenceRequest) => {
  const res = await absenceApi.Create(data);
  return res;
};

const updateAbsence = async (data: AbsenceRequest) => {
  const res = await absenceApi.Update(data);
  return res;
};

const deleteAbsence = async (id: string) => {
  await absenceApi.RemoveOwnAbsence(id);
};

const approveAbsence = async (absenceGuid: string) => {
  await absenceApi.ApproveAbsence(absenceGuid);
};

const disapproveAbsence = async ({ guid, req }: { guid: string; req: AbsenceManagerCommentRequest | undefined }) => {
  const res = await absenceApi.DisapproveAbsence(guid, req);
  return res;
};
export const useCreateAbsence = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAbsence,
    onMutate: async (res) => {
      await queryClient.cancelQueries({ queryKey: calendarKeys.lists() });

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(calendarKeys.lists());

      // Optimistically update to the new value
      queryClient.setQueryData<AbsenceResponse[]>(calendarKeys.lists(), (old = []) => [...old, res as AbsenceResponse]);

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onSettled: () => {
      queryClient.invalidateQueries(calendarKeys.lists());
    },
  });
};

export const useUpdateAbsence = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAbsence,
    onSettled: () => {
      queryClient.invalidateQueries(calendarKeys.lists());
    },
  });
};

export const useDeleteAbsence = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAbsence,
    onSuccess: () => {
      queryClient.invalidateQueries(calendarKeys.lists());
    },
  });
};

export const useApproveAbsence = (absenceGuid: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: approveAbsence,
    onSuccess: () => {
      queryClient.invalidateQueries(calendarKeys.lists());
      queryClient.invalidateQueries(calendarKeys.byId(absenceGuid));
    },
  });
};

export const useDisapproveAbsence = (guid: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: disapproveAbsence,
    onSuccess: () => {
      queryClient.invalidateQueries(calendarKeys.lists());
      queryClient.invalidateQueries(calendarKeys.byId(guid));
    },
  });
};
