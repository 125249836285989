import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { NewsConfirmedReaders } from 'src/services/api/response/newsConfirmedReadersResponse';

type ParticipantsModalProps = {
  confirmations: NewsConfirmedReaders[];
};

const NewsConfirmedReadersModal = (props: ParticipantsModalProps) => {
  const { confirmations } = props;
  return (
    <>
      {confirmations.length &&
        confirmations.map((user) => (
          <Box
            key={user.UserID}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              m: 2,
            }}
          >
            <Typography variant='body1'>{user.FullName}</Typography>
            {user.ConfirmedAt && (
              <Typography variant='h6' sx={{ ml: 0.5 }}>
                {dayjs(user.ConfirmedAt).format('YYYY-MM-DD HH:mm')}
              </Typography>
            )}
          </Box>
        ))}
    </>
  );
};

export default NewsConfirmedReadersModal;
