export enum AbsenceTypeStringEnum {
  Absence = 'Frånvaro',
  Leave = 'Ledighet',
  BusinessTrip = 'Tjänsteärende',
  RemoteWork = 'Distansarbete',
  SickOrLeave = 'Sjuk eller ledig',
}
export enum AbsenceReasonStringEnum {
  Illness = 'Sjuk',
  VAB = 'VAB',
  Flex = 'Flex',
  OffDuty = 'Tjänstledigt',
  ParentalLeave = 'Föräldraledigt',
  Vacation = 'Semester',
  BusinessTrip = 'Tjänsteärende',
  RemoteWork = 'Distansarbete',
  Other = 'Övrigt',
}
