export enum RoutesEnum {
  Default = '/NotFound',
  Login = '/login',
  Start = '/',
  CallbackPage = '/callback',
  SilentRenewPage = '/silentrenew',
  Calendar = '/calendar',
  AbsenceReport = '/admin/absence/report',
  UnAnsweredAbsence = '/admin/absence/unanswered',
  ScheduleReport = '/admin/schedule',
  Career = '/career',
  Form = '/form',
  Employee = '/employee',
  Progress = '/progress',
  Profile = '/profile',
  LunchOrder = '/lunchOrder',
  Admin = '/admin',
  AdminLunch = '/admin/lunch',
  AdminForm = '/admin/form',
  AdminEditForm = '/admin/form/:formId/edit',
}
