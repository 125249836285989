import { BaseAPI } from 'src/services/api/baseApi';
import { AxiosInstance, AxiosInstanceFormData } from 'src/services/axios/interceptors';
import { ChatRoomMessageResponse } from 'src/services/api/response/chatResponse';
import { ChatRoomMessageOptions } from 'src/services/api//options/chatOptions';
import { ChatRoomMessageRequest } from 'src/services/api//request/chatRequest';

export class ChatRoomMessageAPI extends BaseAPI<ChatRoomMessageResponse, ChatRoomMessageOptions, ChatRoomMessageRequest> {
  constructor() {
    super('ChatroomMessage');
  }

  public CreateFormDataImage = (req: FormData) =>
    AxiosInstanceFormData.post(`${this.controllerPath}/uploadMediaImage`, req, {
      transformRequest: (data) => {
        return data;
      },
    });

  public CreateFormDataDocument = (req: FormData) =>
    AxiosInstanceFormData.post(`${this.controllerPath}/uploadMediaDocuments`, req, {
      transformRequest: (data) => {
        return data;
      },
    });

  public LikeMessage = (id: number) => AxiosInstance.post(`${this.controllerPath}/${id}/like`);

  public RemoveLikeMessage = (id: number) => AxiosInstance.delete(`${this.controllerPath}/${id}/like`);
}
