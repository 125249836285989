import ModalTemplate from 'src/shared/templates/modalTemplate';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { ControlledTextInput } from 'src/shared/components/form/controlledTextInput';
import { useCallback, useEffect, useState } from 'react';
import ControlledSelectCategory from 'src/shared/components/form/controlledSelectCategory';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import { useTranslation } from 'react-i18next';
import ControlledTextEditor from 'src/shared/components/form/controlledTextEditor';
import ControlledSelectDepartment from 'src/shared/components/form/controlledSelectDepartment';
import { ControlledSwitch } from 'src/shared/components/form/controlledSwitch';
import { INewsDocument, NewsResponse } from 'src/services/api/response/newsResponse';
import ConsoleHelper from 'src/services/helpers/consoleHelper';
import { NewsRequest } from 'src/services/api/request/newsRequest';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import FileDropZone from 'src/shared/components/form/fileDropZone';
import ImageDropZone from 'src/shared/components/form/imageDropZone';
import { toast } from 'react-toastify';
import { FormErrorSummary } from 'src/shared/components/form/formErrorSummary';
import { useFetchDepartments } from 'src/services/helpers/useFetchDepartments';
import { useFetchNewsCategories } from 'src/modules/home/hooks/queries/useFetchNewsCategories';
import { NewsSchema } from 'src/shared/schemas/newsSchema';
import { CategoriesEnum } from 'src/shared/enums/categories.enum';
import { useQueryClient } from '@tanstack/react-query';
import { usePostNewsDocs, usePostNewsImage } from 'src/modules/home/hooks/mutations/useFormData';
import { useEditNews } from 'src/modules/home/hooks/mutations/useNews';
import { UserTypeEnum } from 'src/shared/enums/userType.enum';
import { useGlobals } from 'src/services/contexts/appContext';
import ControlledDateTimePicker from 'src/shared/components/form/controlledDateTimePicker';
import SaveAndUnpublishButtons from 'src/shared/components/atoms/saveAndUnpublishButtons';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';

type EditNewsModalProps = {
  newsItem: NewsResponse;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  unPublish: () => void;
};

export const EditNewsModal = (props: EditNewsModalProps) => {
  dayjs.extend(utc);
  const { modalOpen, setModalOpen, newsItem, unPublish } = props;
  const { t } = useTranslation('news');
  const queryClient = useQueryClient();
  const [idToEdit, setIdToEdit] = useState<number>(0);
  const [currentImageID, setCurrentImageID] = useState<number | null>(null); // ID för befintlig bild på nyhet
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(null); // URL för befintlig bild på nyhet
  const [imageMedia, setImageMedia] = useState<File | undefined>(undefined); // Fil för att visa lokalt
  const [currentFiles, setCurrentFiles] = useState<INewsDocument[]>([]); // Befintliga dokument på nyhet
  const [mediaFilesFormData, setMediaFilesFormData] = useState<File[]>([]); // Formdata för nya filer att posta
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  // useQuery
  const departmentsOptions = useFetchDepartments(false);
  const categoriesOptions = useFetchNewsCategories(false);
  // useMutation
  const uploadImage = usePostNewsImage();
  const uploadDocs = usePostNewsDocs();
  const editNews = useEditNews();
  const { loggedInUser } = useGlobals();
  const textEditorInitalized = useCallback(() => {
    return null;
  }, []);

  // Sätt state för nyhet som inte används i form
  const setLocaleStates = useCallback(async () => {
    setCurrentImageID(newsItem.ImageID);
    setCurrentFiles(newsItem.Documents);
    setCurrentImageURL(newsItem.Image);
    setIdToEdit(newsItem.ID);
  }, [newsItem]);

  const onPressRemoveImageOld = () => {
    setCurrentImageID(null);
    setCurrentImageURL(null);
  };
  // Metoder för hantera bild
  const saveImageLocally = (file: File | undefined) => {
    if (file) {
      onPressRemoveImageOld();
      setImageMedia(file);
    } else {
      setImageMedia(undefined);
    }
  };

  // Metoder för hantera dokument
  const saveDocumentsLocally = (files: File[]) => {
    setMediaFilesFormData(files);
  };

  const onClickRemoveOldFile = (file: INewsDocument) => {
    const formDataFiles = currentFiles ?? [];
    setCurrentFiles(formDataFiles?.filter((item) => item !== file));
  };

  const formMethods = useForm<NewsRequest>({
    resolver: yupResolver(NewsSchema(t)),
    defaultValues: newsItem, // TODO: newsItem kan vara undefined  kategori populeras inte alltid korrekt t ex
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;
  const watchCategoryId = watch('CategoryId');

  useEffect(() => {
    setLocaleStates();
  }, [setLocaleStates]);

  useEffect(() => {
    if (newsItem && categoriesOptions.data && departmentsOptions.data) {
      ConsoleHelper.log(newsItem, 'newsItem');
      const currentCategoryId =
        (categoriesOptions.data !== undefined &&
          categoriesOptions.data.filter((item) => item.Name.trim().toLowerCase() === newsItem.Category.trim().toLowerCase())[0].Id) ||
        0;
      reset({
        Heading: newsItem.Heading,
        CategoryId: currentCategoryId,
        PublishedDate: newsItem.PublishedDate,
        DeadlineDate: currentCategoryId === CategoriesEnum.Activities ? newsItem.DeadlineDate : undefined,
        ActivityDate: currentCategoryId === CategoriesEnum.Activities ? dayjs.utc(newsItem.ActivityDate).format('YYYY-MM-DDTHH:mm:00') : undefined,
        ImageMediaId: 0,
        Teaser: newsItem.Teaser,
        BodyText: newsItem.BodyText,
        PDFs: newsItem.PDFs,
        CanInvite: newsItem.HasManyInvinted,
        CanBeCommented: newsItem.CanBeCommented,
        Departments: newsItem.Departments ?? [],
        DepartmentIds: newsItem.DepartmentIds,
        ShowConfirmAction: newsItem.ShowConfirmAction,
        SendPush: false,
      });
    }
  }, [reset, newsItem, categoriesOptions.data, departmentsOptions.data]);

  const onSubmit = async (data: NewsRequest) => {
    let imageID = currentImageID ?? null;
    const docIDs: number[] = [];

    // Bild först
    if (imageMedia !== undefined) {
      try {
        const image = await uploadImage.mutateAsync(imageMedia);
        ConsoleHelper.log('RES upload image', image);
        imageID = image;
      } catch (error: any) {
        toast.error(t('failedSaveImage'));
      }
    }

    // Om befintliga dokument
    if (currentFiles) {
      currentFiles.forEach((element) => {
        docIDs.push(element.ID);
      });
    }

    if (mediaFilesFormData) {
      const documentPromises = mediaFilesFormData.map((document) => uploadDocs.mutateAsync(document));
      try {
        const documents = await Promise.all(documentPromises);
        ConsoleHelper.log('RES upload documents', documents);
        documents.forEach((doc) => docIDs.push(doc));
      } catch (error: any) {
        ConsoleHelper.log('ERROR upload documents', error?.response);

        toast.error(t('failedSaveDoc'));
      }
    }

    const putData: NewsRequest = {
      ID: idToEdit,
      CategoryId: data.CategoryId ?? 8,
      Heading: data.Heading,
      Teaser: data.Teaser,
      BodyText: data.BodyText,
      ImageMediaId: imageID,
      PublishedDate: dayjs(data.PublishedDate).format('YYYY-MM-DDTHH:mm:00'),
      ActivityDate: dayjs(data.ActivityDate).format('YYYY-MM-DDTHH:mm:00'),
      DeadlineDate: data.CategoryId === CategoriesEnum.Activities ? dayjs(data.DeadlineDate).format('YYYY-MM-DDTHH:mm:00') : '',
      DocumentMediaIds: docIDs?.length > 0 ? docIDs : undefined, // Får 500 om vi skickar tom array
      Departments: data.Departments,
      DepartmentIds: data.Departments ? (data.Departments.map((dep) => dep.ID).filter((id) => id !== undefined) as number[]) : undefined,
      SendPush: data.SendPush,
      CanBeCommented: data.CanBeCommented,
      CanInvite: data.CanInvite,
      ShowConfirmAction: data.ShowConfirmAction,
    };

    ConsoleHelper.log('putdata', putData);

    editNews.mutate(putData, {
      onSuccess: (res) => {
        ConsoleHelper.log('RES save news:: ', res);
        if (!editNews.isLoading) {
          queryClient.invalidateQueries(['notifications']); // updating the notification-icon[refetch]
          setModalOpen(false);
          toast.success(t('newsIsSavedAndPublished'));
        }
      },
      onError: (error) => {
        ConsoleHelper.log('ERR post /News::', error);
        toast.error(t('Oopsy daisy!', `${t('somethingWentWrong')}: ${error ?? t('unknownError')}`));
      },
    });
  };

  // prevents the modal from closing when clicking outside the modal
  const handleClose = (event?: React.MouseEvent<HTMLElement>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false);
    }
  };
  return (
    <>
      <ModalTemplate
        isOpen={modalOpen}
        onClose={handleClose}
        isLoading={false}
        title={t('edit')}
        content={
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledTextInput heading={t('heading')} name='Heading' size='md' />
              {categoriesOptions && (
                <ControlledSelectCategory
                  label={t('chooseCategory')}
                  name='CategoryId'
                  selectItems={categoriesOptions.data ?? []}
                  info={t('category')}
                />
              )}
              <Box>
                <ControlledDatePicker name='PublishedDate' label={`${t('publishedDate')}*`} />
                <Box sx={{ mb: 1.5 }} />
              </Box>

              {watchCategoryId === CategoriesEnum.Activities && (
                <Stack direction={{ xs: 'column', md: 'row' }} columnGap={4} rowGap={0}>
                  <Box>
                    <ControlledDateTimePicker name='ActivityDate' label={t('activityDate')} info={t('exactactivityDate')} />
                  </Box>
                  <Box>
                    <ControlledDatePicker name='DeadlineDate' label={t('deadlineDate')} info={t('deadlineDateRegisterInfo')} />
                  </Box>
                </Stack>
              )}
              <ImageDropZone
                onFileChange={(image) => saveImageLocally(image)}
                onPressDeleteInitialImage={onPressRemoveImageOld}
                initialImage={currentImageURL ?? undefined}
              />

              <ControlledTextInput name='Teaser' size='md' heading={t('teaser')} />
              <ControlledTextEditor name='BodyText' initCallback={textEditorInitalized} heading={t('postBody')} />
              <FileDropZone
                onFilesChange={(files) => saveDocumentsLocally(files)}
                onPressDeleteInitialFile={onClickRemoveOldFile}
                initialFiles={currentFiles ?? []}
              />
              <ControlledSelectDepartment label={t('departments')} name='Departments' selectItems={departmentsOptions.data ?? []} />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  mt: '20px',
                }}
              >
                <Stack spacing={2} justifyContent='flex-start' alignItems='flex-start'>
                  <ControlledSwitch
                    labelPlacement='start'
                    label={<Typography variant='subtitle2'>{t('pushNotificationToggle')}</Typography>}
                    name='SendPush'
                    info={t('sendPushNoticeInfo')}
                  />
                  <ControlledSwitch
                    labelPlacement='start'
                    label={<Typography variant='subtitle2'>{t('allowCommentsToggle')}</Typography>}
                    name='CanBeCommented'
                    info={t('allowCommentsInfo')}
                  />
                  <ControlledSwitch
                    labelPlacement='start'
                    label={<Typography variant='subtitle2'>{t('requestReadConfirmation')}</Typography>}
                    name='ShowConfirmAction'
                    info={t('requestReadConfirmationInfo')}
                  />
                  {watchCategoryId === CategoriesEnum.Activities && (
                    <>
                      <ControlledSwitch
                        labelPlacement='start'
                        label={<Typography variant='subtitle2'>{t('canInviteToggle')}</Typography>}
                        name='CanInvite'
                        info={t('allowPlusOnesInfo')}
                      />
                    </>
                  )}
                </Stack>
                <FormErrorSummary translationNamespace='news' errors={errors} />
                {newsItem !== undefined &&
                  (loggedInUser?.UserType === UserTypeEnum.Admin ||
                    (loggedInUser?.UserType === UserTypeEnum.Editor && loggedInUser.GUID === newsItem.UserID)) && (
                    <SaveAndUnpublishButtons disabled={editNews.isLoading} onClick={() => setShowDeleteDialog(true)} />
                  )}
              </Box>
            </form>
          </FormProvider>
        }
      />
      <ConfirmDialogModal
        open={showDeleteDialog}
        dialogTitle={t('common:confirm')}
        dialogText={t('news:confirmUnpublish')}
        handleClose={() => setShowDeleteDialog(false)}
        confirmButtonClick={() => {
          setShowDeleteDialog(false);
          unPublish();
        }}
        confirmTextButton={t('common:ok')}
        cancelTextButton={t('common:cancel')}
      />
    </>
  );
};
