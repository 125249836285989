import { FormControlLabel, Switch, Typography, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type ControlledSwitchProps = {
  label: JSX.Element;
  name: string;
  info?: string;
  labelPlacement: any;
  disabled?: boolean;
};

export const ControlledSwitch = (props: ControlledSwitchProps) => {
  const { label, name, info, labelPlacement, disabled } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <FormControlLabel
              label={label}
              labelPlacement={labelPlacement}
              control={<Switch checked={field.value === true || field.value === 'true'} onChange={(e) => field.onChange(e)} disabled={disabled} />}
              sx={{ ml: '0' }}
            />
            {info && (
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  fontStyle: 'italic',
                }}
                variant='subtitle1'
              >
                {info}
              </Typography>
            )}
          </Box>
        );
      }}
    />
  );
};

ControlledSwitch.defaultProps = {
  info: undefined,
  disabled: false,
};
