import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SaveAndUnpublishProps = {
  disabled: boolean;
  onClick: () => void;
};

export default function SaveAndUnpublishButtons(props: SaveAndUnpublishProps) {
  const { disabled, onClick } = props;
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Button disabled={disabled} variant='contained' type='submit' sx={{ mt: '25px' }}>
        {t('common:saveAndUpdate')}
      </Button>
      <Button variant='outlined' color='error' onClick={onClick} disabled={disabled} sx={{ mt: '25px' }}>
        {t('common:unPublish')}
      </Button>
    </Box>
  );
}
