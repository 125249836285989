// import { People } from '@mui/icons-material';
import { Button, Popover } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsConfirmedReaders } from 'src/services/api/response/newsConfirmedReadersResponse';
import { Colors } from 'src/shared/components/styles/colors';
import NewsConfirmedReadersModal from './newsConfirmedReadersModal';

type ParticipantsButtonProps = {
  items: NewsConfirmedReaders[];
};

const NewsConfirmedReadersButton = (props: ParticipantsButtonProps) => {
  const { items } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleParticipantClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openParticipants = Boolean(anchorEl);
  const id = openParticipants ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        sx={{ color: Colors.ACCENT_DARK }}
        // endIcon={<People />}
        onClick={handleParticipantClick}
      >
        {t('news:show')} {items?.length} {t('news:readers')}
      </Button>
      <Popover
        id={id}
        open={openParticipants}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <NewsConfirmedReadersModal confirmations={items} />
      </Popover>
    </>
  );
};

export default NewsConfirmedReadersButton;
