import { AxiosInstance } from 'src/services/axios/interceptors';
import { BaseAPI } from 'src/services/api/baseApi';
import { CareerOptions } from 'src/services/api/options/careerOptions';
import { CareerRequest } from 'src/services/api/request/careerRequest';
import { CareerResponse } from 'src/services/api/response/careerResponse';
import { CareerDTO } from 'src/services/api/models';

export class CareerAPI extends BaseAPI<CareerResponse, CareerOptions, CareerRequest> {
  constructor() {
    super('Career');
  }

  public Unpublish = (guid: string) => AxiosInstance.post(`${this.controllerPath}/${guid}/unpublish`);

  public SendjobIntress = (id: string, req: CareerDTO) => AxiosInstance.post(`${this.controllerPath}/${id}/jobapplication`, req);

  public RemovejobIntress = (id: string) => AxiosInstance.delete(`${this.controllerPath}/${id}/jobapplication`);
}
